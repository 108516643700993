// ImageDisplay.js
import React from 'react';
import './ImageDisplay.css';
import { FaSprayCanSparkles } from "react-icons/fa6";


const ImageDisplay = ({ vehicleType, color, tintPercentage, rimColor, setIsLoading, isLoading, setLoadedCount, loadedCount }) => {
    const getImageSrc = () => `/images/${vehicleType}/body/${vehicleType}-${color}.png`;
    const getRimImageSrc = () => `/images/${vehicleType}/rims/${vehicleType}-${rimColor}.png`;
    const getTintImageSrc = () => `/images/${vehicleType}/mirrors/${vehicleType}-tint.png`;
    const getTintStyle = () => ({ opacity: tintPercentage / 100 });
    const handleImageLoaded = () => {
        setLoadedCount(loadedCount++);
        if (loadedCount == 3) {
            setTimeout(() => {
                setIsLoading(false)
            }, 500);

        }
    };
    return (
        <div className={isLoading ? 'image-display loading' : 'image-display'}>
            {isLoading && <div className="loader"><FaSprayCanSparkles />
            </div>}
            <img src={getImageSrc()} alt={`Vehículo ${vehicleType} de color ${color}`} onLoad={handleImageLoaded} />
            {vehicleType && (
                <img
                    src={getTintImageSrc()}
                    alt={`Sombreado para ${vehicleType}`}
                    style={getTintStyle()}
                    className="tint-image"
                    onLoad={handleImageLoaded}
                />
            )}
            <img src={getRimImageSrc()} alt={`Rim de color ${rimColor}`} className="rim-image" onLoad={handleImageLoaded} />
        </div>
    );
};

export default ImageDisplay;