import React from 'react';
import QuoteForm from './QuoteForm';
import './PopupForm.css';  // Assume CSS for basic styling
import { IoCloseCircleOutline } from "react-icons/io5";


const PopupForm = ({ isOpen, onClose, color, rimColor, tintPercentage }) => {
    if (!isOpen) return null;

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <button onClick={onClose} className="close-button"><IoCloseCircleOutline />
                </button>
                <QuoteForm color={color} rimColor={rimColor} tintPercentage={tintPercentage} onClose={onClose} />
            </div>
        </div>
    );
};

export default PopupForm;
