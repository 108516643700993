import React from 'react';
import './ColorSelector.css';

const ColorSelector = ({ color, setColor, colors, setIsLoading, setLoadedCount }) => {
    function handleSetColorClick(color) {

        setColor(color);  // Actualiza el tipo de vehículo
        setIsLoading(true);   // Establece el estado de carga a true
        setLoadedCount(2);

    }

    return (
        <div className="color-selector-card">
            <h2>Select Color</h2>
            <div className="color-selector">
                {colors.map(({ name, hex }) => (
                    <div
                        key={name}
                        className={`color-option ${color === hex ? 'active' : ''}`}
                        onClick={() => handleSetColorClick(name)}
                        style={{ backgroundColor: hex }}
                        title={name} // Opcional: para mostrar el nombre del color como un tooltip al pasar el mouse.
                    ></div>
                ))}
            </div>
        </div>
    );
}

export default ColorSelector;
