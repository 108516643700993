import React, { useState } from 'react';
import './QuoteForm.css'; // Asegúrate de tener el archivo CSS para estilos

const QuoteForm = ({ color, rimColor, tintPercentage, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        lastName: '',
        phone: '',
        email: '',
        brand: '',
        model: '',
        year: '',
        rimColor: '',
        color: '',
        tintPercentage: ''
    });


    const carData = {
        Toyota: ['Corolla', 'Camry', 'Prius', 'Highlander', 'Land Cruiser', 'RAV4', 'Yaris', 'Avalon'],
        Ford: ['Fiesta', 'Focus', 'Mustang', 'Explorer', 'F-150', 'Escape', 'Edge', 'Expedition'],
        Chevrolet: ['Malibu', 'Cruze', 'Impala', 'Tahoe', 'Silverado', 'Equinox', 'Camaro', 'Corvette'],
        Honda: ['Civic', 'Accord', 'CR-V', 'Pilot', 'Fit', 'Odyssey', 'HR-V', 'Ridgeline'],
        Nissan: ['Altima', 'Sentra', 'Maxima', 'Rogue', 'Murano', 'Pathfinder', 'Titan', 'Leaf'],
        BMW: ['3 Series', '5 Series', '7 Series', 'X3', 'X5', 'X7', 'M3', 'M5'],
        MercedesBenz: ['C-Class', 'E-Class', 'S-Class', 'GLE', 'GLC', 'GLA', 'GLS', 'A-Class'],
        Audi: ['A3', 'A4', 'A6', 'A8', 'Q3', 'Q5', 'Q7', 'TT'],
        Volkswagen: ['Golf', 'Passat', 'Jetta', 'Tiguan', 'Beetle', 'Atlas', 'GTI', 'Touareg'],
        Hyundai: ['Elantra', 'Sonata', 'Tucson', 'Santa Fe', 'Kona', 'Veloster', 'Genesis', 'Palisade'],
        Kia: ['Rio', 'Forte', 'Optima', 'Sorento', 'Soul', 'Sportage', 'Telluride', 'Stinger'],
        Mazda: ['Mazda3', 'Mazda6', 'CX-5', 'CX-9', 'MX-5 Miata', 'CX-3', 'CX-30'],
        Subaru: ['Impreza', 'Legacy', 'Forester', 'Outback', 'Crosstrek', 'WRX', 'Ascent'],
        Jeep: ['Wrangler', 'Grand Cherokee', 'Cherokee', 'Renegade', 'Compass', 'Gladiator'],
        Tesla: ['Model S', 'Model 3', 'Model X', 'Model Y'],
        Lexus: ['IS', 'ES', 'GS', 'LS', 'RX', 'GX', 'LX', 'NX'],
        Volvo: ['S60', 'S90', 'XC40', 'XC60', 'XC90', 'V60', 'V90'],
        Pagani: ['Huayra', 'Zonda', 'Zonda R', 'Huayra Roadster', 'Huayra BC'] // Modelos de Pagani
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const validateForm = () => {
        const { name, lastName, phone, email, brand, model, year } = formData;
        const emailRegex = /\S+@\S+\.\S+/;
        const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

        if (!name || !lastName || !phone || !email || !brand || !model || !year) {
            alert("All fields are required.");
            return false;
        }

        if (!emailRegex.test(email)) {
            alert("Please enter a valid email address.");
            return false;
        }

        if (!phoneRegex.test(phone)) {
            alert("Please enter a valid phone number.");
            return false;
        }

        return true; // All validations passed
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validateForm()) return; // Stop the submission if the form is not valid

        sendDataToGoHighLevel(formData).then(() => {
            onClose(); // Cierra el formulario solo si el envío es exitoso
        }).catch((error) => {
            console.error('Failed to send data:', error);
        });
    };

    const formatText = text => {
        return text.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
    };

    const sendDataToGoHighLevel = async (formData) => {
        const webhookUrl = 'https://hooks.zapier.com/hooks/catch/354012/37v1btx/';

        try {
            const response = await fetch(webhookUrl, {
                method: "POST",
                mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            const result = 'ok';

            if (response) {
                console.log('Success:', result);
                alert('Data sent successfully!');
            } else {
                console.error('Failed to send data:', result);
                alert('Failed to send data.');
            }
        } catch (error) {
            console.error('Error sending data:', error);
            alert('Error sending data.');
        }
    };


    return (
        <form onSubmit={handleSubmit} className="quote-form">
            <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input id="name" type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} />
            </div>

            <div className="form-group">
                <label htmlFor="lastName">Last Name:</label>
                <input id="lastName" type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} />
            </div>

            <div className="form-group">
                <label htmlFor="phone">Phone:</label>
                <input id="phone" type="tel" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} />
            </div>

            <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input id="email" type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
            </div>

            <div className="form-group">
                <label htmlFor="brand">Brand of the Car:</label>
                <select id="brand" name="brand" value={formData.brand} onChange={handleChange}>
                    <option value="">Select Brand</option>
                    {Object.keys(carData).map(brand => (
                        <option key={brand} value={brand}>{brand}</option>
                    ))}
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="model">Model of the Car:</label>
                <select id="model" name="model" value={formData.model} onChange={handleChange} disabled={!formData.brand}>
                    <option value="">Select Model</option>
                    {formData.brand && carData[formData.brand].map(model => (
                        <option key={model} value={model}>{model}</option>
                    ))}
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="year">Year of the Car:</label>
                <input id="year" type="text" name="year" placeholder="Year of the Car" value={formData.year} onChange={handleChange} />
            </div>

            <div className="form-group">
                <label>Color:</label>
                <input type="text" placeholder="Color" name='color' value={formData.color = formatText(color)} disabled />
            </div>

            <div className="form-group">
                <label>Rim Color:</label>
                <input type="text" placeholder="Rim Color" name='rimColor' value={formData.rimColor = formatText(rimColor)} disabled />
            </div>

            <div className="form-group">
                <label>Tint Percentage:</label>
                <input type="text" placeholder="Tint Percentage" name='tintPercentage' value={formData.tintPercentage = `${tintPercentage}%`} disabled />
            </div>
            <div className='button-container'>
                <button type="submit">Request Quote</button>
            </div>

        </form>
    );
};

export default QuoteForm;