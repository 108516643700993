import React from 'react';
import './LogoEmpresa.css'; // Asegúrate de importar el CSS

const LogoEmpresa = () => (
    <div className="logo-container">
        <img src="/logo192.png" alt="Logo Empresa" className="logo-empresa" />
    </div>
);

export default LogoEmpresa;
