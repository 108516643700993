import React from 'react';
import './TintPercentageSelector.css';

const TintPercentageSelector = ({ tintPercentage, setTintPercentage, percentages }) => (
    <div className="tint-percentage-selector-card">
        <h2>Select Tint Percentage</h2>
        <div className="tint-percentage-selector">
            {percentages.map((percentage) => (
                <button
                    key={percentage}
                    className={`tint-percentage-option ${tintPercentage === percentage ? 'active' : ''}`}
                    onClick={() => setTintPercentage(percentage)}
                >
                    {percentage}%
                </button>
            ))}
        </div>
    </div>
);

export default TintPercentageSelector;
