import React from 'react';
import './PopupOpenButton.css';

const PopupOpenButton = ({ onOpen }) => (
    <button onClick={onOpen} className="popup-open-button">
        Request a Quote
    </button>
);

export default PopupOpenButton;
