import React, { useState } from 'react';
import './App.css';
import VehicleTypeSelector from './components/VehicleTypeSelector';
import ColorSelector from './components/ColorSelector';
import TintPercentageSelector from './components/TintPercentageSelector';
import ImageDisplay from './components/ImageDisplay';
import LogoEmpresa from './components/LogoEmpresa';
import RimColorSelector from './components/RimColorSelector';
import PopupForm from './components/PopupForm';
import PopupOpenButton from './components/PopupOpenButton';

function App() {
  const [vehicleType, setVehicleType] = useState('sedan');
  const [color, setColor] = useState('black');
  const [rimColor, setRimColor] = useState('black');
  const [tintPercentage, setTintPercentage] = useState('70');
  const percentages = ['70', '50', '35', '20', '5'];
  const [isLoading, setIsLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loadedCount, setLoadedCount] = useState(0);

  const handleOpenPopup = () => setIsPopupOpen(true);
  const handleClosePopup = () => setIsPopupOpen(false);

  const colors = [
    { name: 'white', codigo: '114', hex: '#e3dfdc' },
    { name: 'matte-white', codigo: '020', hex: '#fefefe' },
    { name: 'antique-white', codigo: '090', hex: '#f3f2dd' },
    { name: 'pearl-gray', codigo: '011', hex: '#cecfca' },
    { name: 'medium-gray', codigo: '031', hex: '#919690' },
    { name: 'satin', codigo: '120', hex: '#7e8891' },
    { name: 'dark-gray', codigo: '041', hex: '#3b3f3e' },
    { name: 'black', codigo: '012', hex: '#0a090e' },
    { name: 'matte-black', codigo: '022', hex: '#191d20' },
    { name: 'deep-mahogany-brown', codigo: '019', hex: '#312019' },
    { name: 'russet-brown', codigo: '029', hex: '#4a2514' },
    { name: 'saddle-brown', codigo: '139', hex: '#67441e' },
    { name: 'satin-gold', codigo: '131', hex: '#5e5a2d' },
    { name: 'putty', codigo: '059', hex: '#645a50' },
    { name: 'sandstone', codigo: '089', hex: '#998b71' },
    { name: 'tan', codigo: '023', hex: '#8d7843' },
    { name: 'fawn', codigo: '099', hex: '#d8d497' },
    { name: 'egg-shell', codigo: '130', hex: '#f5e4dd' },
    { name: 'vista-yellow', codigo: '085', hex: '#e9e15c' },
    { name: 'sunflower', codigo: '025', hex: '#f5b901' },
    { name: 'apricot', codigo: '064', hex: '#e59524' },
    { name: 'harvest-gold', codigo: '105', hex: '#b99324' },
    { name: 'terra-cotta', codigo: '024', hex: '#903812' },
    { name: 'light-orange', codigo: '054', hex: '#ee620d' },
    { name: 'bright-orange', codigo: '014', hex: '#e14d11' },
    { name: 'perfect-match-red', codigo: '263', hex: '#a8150e' },
    { name: 'tomato-red', codigo: '063', hex: '#a71810' },
    { name: 'geranium', codigo: '063', hex: '#941415' },
    { name: 'cardinal-red', codigo: '053', hex: '#900e18' },
    { name: 'deep-red', codigo: '023', hex: '#791619' },
    { name: 'burgundy', codigo: '058', hex: '#4F0D17' },
    { name: 'mauve', codigo: '193', hex: '#BE647E' },
    { name: 'magenta', codigo: '103', hex: '#D74874' },
    { name: 'process-magenta', codigo: '273', hex: '#AB1D45' },
    { name: 'violet', codigo: '088', hex: '#AE83BB' },
    { name: 'dark-violet', codigo: '098', hex: '#683266' },
    { name: 'purple', codigo: '048', hex: '#391E3D' },
    { name: 'royal-purple', codigo: '038', hex: '#2C2165' },
    { name: 'indigo', codigo: '027', hex: '#0A1322' },
    { name: 'light-navy', codigo: '197', hex: '#091336' },
    { name: 'shadow-blue', codigo: '177', hex: '#2E3E57' },
    { name: 'sapphire-blue', codigo: '037', hex: '#102368' },
    { name: 'vivid-blue', codigo: '017', hex: '#112D81' },
    { name: 'intense-blue', codigo: '047', hex: '#153E9A' },
    { name: 'olympic-blue', codigo: '057', hex: '#2762C6' },
    { name: 'peacock-blue', codigo: '077', hex: '#5E9CD7' },
    { name: 'wedgewood-blue', codigo: '187', hex: '#5F7A8B' },
    { name: 'robin-egg-blue', codigo: '086', hex: '#88CAD6' },
    { name: 'aqua', codigo: '176', hex: '#5EBCA4' },
    { name: 'dark-aqua', codigo: '307', hex: '#349291' },
    { name: 'teal', codigo: '096', hex: '#1E6082' },
    { name: 'bermuda-blue', codigo: '357', hex: '#1D4D5B' },
    { name: 'apple-green', codigo: '196', hex: '#3E961B' },
    { name: 'kelly-green', codigo: '046', hex: '#1E773F' },
    { name: 'bright-green', codigo: '186', hex: '#16672D' },
    { name: 'dark-green', codigo: '056', hex: '#0E4027' },
    { name: 'forest-green', codigo: '066', hex: '#15342E' },
    { name: 'bottle-green', codigo: '276', hex: '#071F0F' },
  ];

  return (
    <div className="app" >
      <div className="main-row">
        <div>
          <VehicleTypeSelector vehicleType={vehicleType} setVehicleType={setVehicleType} setIsLoading={setIsLoading} setLoadedCount={setLoadedCount} />
        </div>
        <div>
          <LogoEmpresa />
          <ImageDisplay vehicleType={vehicleType} color={color} tintPercentage={100 - tintPercentage} rimColor={rimColor} setIsLoading={setIsLoading} isLoading={isLoading} loadedCount={loadedCount} setLoadedCount={setLoadedCount} />
        </div>
        <div>
          <TintPercentageSelector tintPercentage={tintPercentage} setTintPercentage={setTintPercentage} percentages={percentages} />
        </div>
      </div>
      <div className="secondary-row">
        <div>
          <ColorSelector color={color} setColor={setColor} colors={colors} setIsLoading={setIsLoading} setLoadedCount={setLoadedCount} />
        </div>
        <div>
          <RimColorSelector rimColor={rimColor} setRimColor={setRimColor} setIsLoading={setIsLoading} setLoadedCount={setLoadedCount} />
          <PopupOpenButton onOpen={handleOpenPopup} />
          <PopupForm isOpen={isPopupOpen} onClose={handleClosePopup} color={color} rimColor={rimColor} tintPercentage={tintPercentage} />
        </div>
      </div>
    </div>

  );
}

export default App;
