import React from 'react';
import './RimColorSelector.css';
import { TbWheel } from "react-icons/tb";


const RimColorSelector = ({ rimColor, setRimColor, setIsLoading, setLoadedCount }) => {
    const availableColors = [
        { name: 'antique-white', hex: '#FFFFFF' },
        { name: 'white', hex: '#FFFFFF' },
        { name: 'apple-green', hex: '#43d935' },
        { name: 'apricot', hex: '#d0ac2a' },
        { name: 'aqua', hex: '#00FFFF' },
        { name: 'beige', hex: '#F5F5DC' },
        { name: 'bermuda-blue', hex: '#80E0E5' },
        { name: 'black', hex: '#000000' },
        { name: 'bottle-green', hex: '#006A4E' },
        { name: 'bright-green', hex: '#66FF00' },
        { name: 'bright-orange', hex: '#FF5F00' },
        { name: 'bright-yellow', hex: '#FFAA1D' },
        { name: 'burgundy', hex: '#800020' },
        { name: 'cardinal-red', hex: '#C41E3A' },
        { name: 'dark-aqua', hex: '#008B8B' },
        { name: 'dark-gray', hex: '#A9A9A9' },
        { name: 'dark-violet', hex: '#9400D3' },
        { name: 'deep-mahogany-brown', hex: '#420D09' },
        { name: 'deep-red', hex: '#8B0000' },
        { name: 'eggshell', hex: '#F0EAD6' },
        { name: 'fawn', hex: '#E5AA70' },
        { name: 'forest-green', hex: '#228B22' },
        { name: 'geranium', hex: '#D8554C' },
        { name: 'harvest-gold', hex: '#DA9100' },
        { name: 'indigo', hex: '#4B0082' },
        { name: 'intense-blue', hex: '#00BFFF' },
        { name: 'kelly-green', hex: '#4CBB17' },
        { name: 'light-navy', hex: '#000080' },
        { name: 'light-orange', hex: '#FFA500' },
        { name: 'magenta', hex: '#FF00FF' },
        { name: 'matte-black', hex: '#0A0A0A' },
        { name: 'matte-white', hex: '#FFFFF0' },
        { name: 'mauve', hex: '#E0B0FF' },
        { name: 'medium-gray', hex: '#989898' },
        { name: 'olympic-blue', hex: '#0073CF' },
        { name: 'peacock-blue', hex: '#33A1C9' },
        { name: 'pearl-gray', hex: '#CECFCA' },
        { name: 'perfect-match-red', hex: '#C30F24' },
        { name: 'process-magenta', hex: '#FF0090' },
        { name: 'purple', hex: '#800080' },
        { name: 'putty', hex: '#DAC2A0' },
        { name: 'robin-egg-blue', hex: '#00CCCC' },
        { name: 'royal-purple', hex: '#7851A9' },
        { name: 'russet-brown', hex: '#80461B' },
        { name: 'saddle-brown', hex: '#8B4513' },
        { name: 'sandstone', hex: '#786D5F' },
        { name: 'sapphire-blue', hex: '#0F52BA' },
        { name: 'satin', hex: '#C2B280' },
        { name: 'satin-gold', hex: '#E6BE8A' },
        { name: 'shadow-blue', hex: '#778BA5' },
        { name: 'sunflower', hex: '#FFC512' },
        { name: 'tan', hex: '#D2B48C' },
        { name: 'teal', hex: '#008080' },
        { name: 'terra-cotta', hex: '#E2725B' },
        { name: 'tomato-red', hex: '#FF6347' },
        { name: 'violet', hex: '#EE82EE' },
        { name: 'vista-yellow', hex: '#FAB000' },
        { name: 'vivid-blue', hex: '#0099CC' },
        { name: 'wedgewood-blue', hex: '#4C7E86' }
    ];


    function handleRimColorClick(color) {
        setRimColor(color);  // Actualiza el tipo de vehículo
        setIsLoading(true);   // Establece el estado de carga a true
        setLoadedCount(2);
    }

    return (
        <div className="rim-color-selector">
            <h2>Select Rim Color</h2>
            <div className="rim-color-options">
                {availableColors.map(color => (
                    <button
                        key={color.name}
                        className={`rim-color-option ${rimColor === color.name ? 'active' : ''}`}
                        onClick={() => handleRimColorClick(color.name)}
                        title={color.name}
                    >
                        <TbWheel
                            className='rim-icon'
                            style={{ color: color.hex }}
                        />
                    </button>
                ))}
            </div>
        </div>
    );
};

export default RimColorSelector;
