import React from 'react';
import './VehicleTypeSelector.css';

const VehicleTypeSelector = ({ vehicleType, setVehicleType, setIsLoading, setLoadedCount }) => {
    // Función para manejar clic en el botón
    function handleVehicleClick(type) {
        setVehicleType(type);  // Actualiza el tipo de vehículo
        setIsLoading(true);   // Establece el estado de carga a true
        setLoadedCount(2);
    }

    return (
        <div className="vehicle-type-selector-card">
            <h2>Select Type of Car</h2>
            <div className="vehicle-type-selector">
                {[
                    { type: 'sedan', icon: '/icons/icon-sedan.png' },
                    { type: 'suv', icon: '/icons/icon-suv.png' },
                    { type: 'truck', icon: '/icons/icon-truck.png' },
                ].map(({ type, icon }) => (
                    <button
                        key={type}
                        className={`vehicle-type-option ${vehicleType === type ? 'active' : ''}`}
                        onClick={() => handleVehicleClick(type)}
                    >
                        <img src={icon} alt={type} className="vehicle-icon" />
                    </button>
                ))}
            </div>
        </div>
    );
}

export default VehicleTypeSelector;
